import { ReactNode } from 'react';
import {
  StyledPreApprovedBadgeBody,
  StyledPreApprovedBadgeContainer,
  StyledPreApprovedBadgeFold,
} from './styles';
import { PreApprovedBadge } from './index';

/**
 * @deprecated `<StyledPreApprovedBadgeFold />` has been replaced with the `<Badge />` component in
 *   `@raylo-tech/raylopay-ui`.
 */

const PreApprovedBadgeContainer = ({
  dataTestId,
  children,
}: {
  dataTestId?: string;
  children: ReactNode;
}) => (
  <StyledPreApprovedBadgeContainer data-testid={dataTestId}>
    <StyledPreApprovedBadgeBody>
      <PreApprovedBadge />
    </StyledPreApprovedBadgeBody>
    <StyledPreApprovedBadgeFold />
    {children}
  </StyledPreApprovedBadgeContainer>
);

export default PreApprovedBadgeContainer;
