import { StyledBreadcrumbs } from './styles';
import { Link } from '@/elements/Link/Link';
import { BreadcrumbItem } from './types';
import { useEffect, useState } from 'react';

const home = { name: 'Home', url: 'https://raylo.com' };

const Breadcrumbs = ({ items }: { items: BreadcrumbItem[] }) => {
  const [position, setPosition] = useState<'fixed' | 'absolute'>('fixed');
  const [isMounted, setIsMounted] = useState(false);

  const handleScroll = () => {
    if (window.scrollY < 136) {
      setPosition('fixed');
    } else {
      setPosition('absolute');
    }
  };

  useEffect(() => {
    // need to set isMounted to true to prevent hydration error when hiding breadcrumbs
    setIsMounted(true);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <StyledBreadcrumbs data-testid="breadcrumbs" $position={position}>
      <a href={home.url} title={home.name} data-testid={`breadcrumb-link-${home.name}`}>
        {home.name}
      </a>
      {items.map(({ name, url }, index) => (
        <Link
          data-testid={`breadcrumb-link-${name}`}
          key={index}
          href={url}
          title={name}
          className={index === items.length - 1 ? 'active' : undefined}
        >
          {name}
        </Link>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
