import NEW_BREAKPOINTS from '@/constants/breakpoints';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT, palette } from '@raylo-tech/raylopay-ui';

const StyledPreApprovedHeaderBanner = styled.div`
  width: 100%;
  background-color: ${palette.white};
  padding: ${px2Rem(16)} ${px2Rem(24)};
`;

const StyledPreApprovedHeaderBannerContent = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${px2Rem(8)};
  cursor: pointer;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    width: auto;
    justify-content: flex-start;
  }
`;

const StyledPreApprovedBadgeContainer = styled.div`
  height: 100%;
  position: relative;
`;

const StyledPreApprovedBadgeBody = styled.div`
  position: absolute;
  left: ${px2Rem(-6)};
  top: ${px2Rem(-14)};
  background: ${palette.blue[400]};
  z-index: 99;
`;

const StyledPreApprovedBadgeInline = styled.div`
  padding: ${px2Rem(5)} ${px2Rem(8)};
  background: ${palette.blue[400]};
  color: ${palette.white};
  height: ${px2Rem(28)};
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  font-size: ${px2Rem(14)};
  border-radius: ${px2Rem(4)};
  width: fit-content;
`;

const StyledPreApprovedBadgeFold = styled.div`
  position: absolute;
  left: ${px2Rem(-6)};
  top: ${px2Rem(14)};
  border-bottom: ${px2Rem(6)} solid transparent;
  border-right: ${px2Rem(6)} solid ${palette.blue[500]};
  height: 0;
  width: 0;
`;

const StyledIneligiblePaymentBox = styled.div<{
  dataTestId?: string;
  borderColor?: string;
}>`
  height: ${px2Rem(85)};
  border: ${px2Rem(1)} solid ${palette.charcoal[200]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${px2Rem(14)};
  border-radius: ${px2Rem(4)};
  color: ${palette.charcoal[500]};
  background-color: ${palette.charcoal[100]};

  & h6 {
    padding: 0;
    margin: 0;
  }
`;

export {
  StyledPreApprovedHeaderBanner,
  StyledPreApprovedHeaderBannerContent,
  StyledPreApprovedBadgeContainer,
  StyledPreApprovedBadgeBody,
  StyledPreApprovedBadgeFold,
  StyledPreApprovedBadgeInline,
  StyledIneligiblePaymentBox,
};
